import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
const EmtiaSelect = ({ emtia, setEmtia, errorEmtia, setErrorEmtia }) => {
  const emtias = [
    { value: 1, label: "Altın" },
    { value: 2, label: "Gümüş" },
    { value: 3, label: "Platin" },
    { value: 101, label: "USD" },
  ];
  const handleChangeEmtia = (event) => {
    setEmtia(event.target.value);
    setErrorEmtia(false);
  };

  return (
    <FormControl variant="standard" sx={{ minWidth: 135 }} error={errorEmtia}>
      <InputLabel id="demo-simple-select-standard-label">
        Emtia Seçiniz
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={emtia}
        onChange={handleChangeEmtia}
      >
        {emtias.map((emtia) => (
          <MenuItem key={emtia.value} value={emtia.value}>
            {emtia.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EmtiaSelect;
