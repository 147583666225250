import { Box, Typography, Button, Divider } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useMemo, useState } from "react";

import { handleExportData, handleExportRows } from "src/utils/helpers/helper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AccountCard from "src/components/Cards/AccountCard.js/AccountCard";
import { pageContainer } from "src/utils/customStyles";
const Accounts = () => {
  const [data, setData] = useState([
    {
      name: {
        firstName: "John",
        lastName: "Doe",
      },
      address: "261 Erdman Ford",
      city: "East Daphne",
      state: "Kentucky",
      MemberId: "123456",
      Phone: "555-1234",
    },
    {
      name: {
        firstName: "Jane",
        lastName: "Doe",
      },
      address: "769 Dominic Grove",
      city: "Columbus",
      state: "Ohio",
      MemberId: "789012",
      Phone: "555-5678",
    },
    // Add more data as needed
  ]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.firstName",
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "name.lastName",
        header: "Last Name",
        size: 150,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 150,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          marginBottom: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() => handleExportData(data)}
          startIcon={<FileDownloadIcon />}
        >
          Tüm Verileri Dışa Aktar
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Tüm Satırları Dışa Aktar
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Bu Sayfadaki Satırları Dışa Aktar
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Seçilmiş Satırları Dışa Aktar
        </Button>
      </Box>
    ),
  });
  return (
    <Box sx={pageContainer}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" sx={{ letterSpacing: "3px" }}>
          Hesaplar
        </Typography>
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
            marginBottom: "2rem",
          }}
        />
      </Box>{" "}
      <AccountCard />
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default Accounts;
