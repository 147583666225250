import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import AuthForm from "src/sections/auth/login/AuthForm";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import dashboardbackground from "../../../assets/Gifs/dashboardbackground";
import dashboardbackground_white from "../../../assets/Gifs/dashboardbackground_white";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";

function Authpage(props) {
  const navigate = useNavigate();
  const [mail, setMail] = React.useState();
  const [id, setId] = React.useState();

  const location = useLocation();
  const { mailValue, UserId } = location.state || {};

  React.useEffect(() => {
    if (mailValue) setMail(mailValue);
    if (UserId) setId(UserId);
  }, [mailValue, UserId]);

  const darkMode = useSelector((state) => state.darkMode.value);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !darkMode ? dashboardbackground : dashboardbackground_white,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    const authCode = sessionStorage.getItem("authCode");
    console.log(authCode);
    if (authCode && authCode.length > 0 && authCode !== null) {
      sessionStorage.removeItem("authCode");
      navigate("/");
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100vw",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Lottie
        options={defaultOptions}
        style={{
          zIndex: "-1",
          position: "absolute",
          width: "100%",
        }}
      />
      <AuthForm mail={mail} id={id} />
    </Box>
  );
}

export default Authpage;
