// component
import { Menu } from "@mui/material";
import { CurrencyExchange } from "@mui/icons-material";
import MenuIcon from "src/assets/Icons/navbar/Menu";
import AccountsIcon from "src/assets/Icons/navbar/AccountsIcon";
import TransfersIcon from "src/assets/Icons/navbar/TransfersIcon";
import AdminListing from "src/assets/Icons/navbar/AdminListing";
import RolesIcon from "src/assets/Icons/navbar/RolesIcon";
import PricesIcon from "src/assets/Icons/navbar/PricesIcon";
import VendorIcon from "src/assets/Icons/navbar/VendorIcon";
import IslmlerIcon from "src/assets/Icons/navbar/IslmlerIcon";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Anasayfa",
    path: "/dashboard/Menu",
    icon: <MenuIcon />,
  },
  {
    title: "Fiyatlar",
    path: "/dashboard/Fiyatlar",
    icon: <PricesIcon />,
  },
  {
    title: "İşlemler",
    path: "/dashboard/islemler",
    icon: <IslmlerIcon />,
  },
  {
    title: "Transferler",
    path: "/dashboard/transferler",
    icon: <TransfersIcon />,
  },
  {
    title: "Hesaplar",
    path: "/dashboard/hesaplar",
    icon: <AccountsIcon />,
  },
  {
    title: "Vendor İşlemleri",
    path: "/dashboard/vendorIslemleri",
    icon: <VendorIcon />,
  },
  {
    title: "Admin İşlemleri",
    path: "/dashboard/adminListeleme",
    icon: <AdminListing />,
  },
  {
    title: "Rol İşlemleri",
    path: "/dashboard/rolIslemleri",
    icon: <RolesIcon />,
  },
];

export default navConfig;
