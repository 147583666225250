import { download, generateCsv, mkConfig } from "export-to-csv";

export function delayForDemo(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 300);
  }).then(() => promise);
}

const csvConfig = (fileName) => {
  return mkConfig({
    fieldSeparator: ";",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename: fileName ? fileName : "dosya",
  });
};
export const handleExportRows = (rows, name) => {
  const rowData = rows.map((row) => row.original);
  const csv = generateCsv(csvConfig(name))(rowData);
  download(csvConfig(name))(csv);
};
export const handleExportData = (data, name) => {
  const csv = generateCsv(csvConfig(name))(data);
  download(csvConfig(name))(csv);
};

export const getBackgroundColor = (commoditySuffix) => {
  switch (commoditySuffix) {
    case "1":
      return "radial-gradient(ellipse farthest-corner at right bottom, #d3b16f 0%, #ffe5b2 8%, #d6b87c 30%, #d6b87c 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffe5b2 0%, #ffe5b2 8%, #D1B464 25%, #5d4a1f 90.5%, #5d4a1f 100%)";
    case "2":
      return "radial-gradient(ellipse farthest-corner at right bottom, #d5d5d5 0%, #b2b2b2 8%, #a3a1a1 30%, #b9b9ba 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #B3B6B5 0%, #BEC0C2 8%, #BEC0C2 25%, #a2a2a1 90.5%, #767673 100%)";
    case "3":
      return "radial-gradient(ellipse farthest-corner at right bottom, #cccccc 0%, #a9a9a9 8%, #808080 30%, #666666 40%, transparent 100%), radial-gradient(ellipse farthest-corner at left top, #e0e0e0 0%, #b3b3b3 8%, #999999 25%, #7f7f7f 90.5%, #4d4d4d 100%)";
    case "4":
      return "radial-gradient(ellipse farthest-corner at right bottom, #e5e4e2 0%, #dcdcdc 25%, #c0c0c0 50%, #c0c0c0 75%, transparent 100%), radial-gradient(ellipse farthest-corner at left top, #f8f8f8 0%, #e0e0e0 20%, #d3d3d3 40%, #c8c8c8 60%, #b0b0b0 80%, #a0a0a0 100%)";
    case "5":
      return "radial-gradient(ellipse farthest-corner at right bottom, #cccccc 0%, #a9a9a9 8%, #a9a9a9 30%, #666666 100%, transparent 100%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #b3b3b3 8%, #b9b9b9 25%, #b9b9b9 90.5%, #c2c2c2 100%)";
    default:
      return "radial-gradient(ellipse farthest-corner at right bottom, #d3b16f 0%, #ffe5b2 8%, #d6b87c 30%, #d6b87c 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffe5b2 0%, #ffe5b2 8%, #D1B464 25%, #5d4a1f 90.5%, #5d4a1f 100%)";
  }
};

export const buttonStyles = (isSelected) => ({
  backgroundColor: isSelected ? "primary.main" : "",
  color: isSelected ? "common.white" : "common.black",
  "&:hover": {
    backgroundColor: isSelected ? "primary.dark" : "",
  },
});

export const formatCurrency = (amount) => {
  // İlk olarak virgül ile binler basamağına ayırma işlemi yapılır
  const formattedAmount = amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Virgülden sonra iki basamaklı kısmı ayırmak için işlem yapılır
  const [integerPart, decimalPart] = formattedAmount.split(".");

  // Ondalık kısmı iki basamağa kadar sıfır eklenerek oluşturulur
  const formattedDecimalPart = decimalPart ? decimalPart.padEnd(2, "0") : "00";

  // Formatlanmış sayı oluşturulur ve geri döndürülür
  return `${integerPart}.${formattedDecimalPart}`;
};
