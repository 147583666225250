import { useState } from "react";
// @mui

import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// mocks_
import account from "../../../_mock/account";
import { useNavigate } from "react-router-dom";
import { setDarkMode } from "src/Redux/slices/darkModeSlice";
import { useDispatch, useSelector } from "react-redux";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";

import LogoutIcon from "src/assets/Icons/Header/Logout";
import AddIcon from "src/assets/Icons/Header/Add";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Anasayfa",
    icon: "eva:home-fill",
  },
  {
    label: "Vendor Ekle",
    icon: "eva:home-fill",
  },
  {
    label: "Tema Değiştir",
    icon: "eva:home-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  //  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [open, setOpen] = useState(null);
  const darkMode = useSelector((state) => state.darkMode.value);
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logOut = () => {
    sessionStorage.removeItem("authCode");
    navigate("/");
  };

  return (
    <>
      <Button
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
        }}
        onClick={() => {
          dispatch(setDarkMode(!darkMode));
          if (!darkMode) {
            localStorage.setItem("darkMode", "true");
            console.log("true");
          } else {
            localStorage.setItem("darkMode", "false");
            console.log("false");
          }
        }}
      >
        {!isMobile && (
          <>
            {!darkMode ? (
              <>
                {" "}
                <NightsStayIcon sx={{ color: "primary.goldtag" }} />
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ color: "text.primary" }}
                >
                  Aydınlık Tema
                </Typography>
              </>
            ) : (
              <>
                <WbTwilightIcon sx={{ color: "primary.goldtag" }} />
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ color: "text.primary" }}
                >
                  Karanlık Tema
                </Typography>
              </>
            )}
          </>
        )}
      </Button>

      {!isMobile && (
        <Button
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
          }}
          onClick={() =>
            navigate("/dashboard/vendorIslemleri", { state: { value: 0 } })
          }
        >
          <AddIcon />
          <Typography variant="subtitle2" noWrap sx={{ color: "text.primary" }}>
            Vendor Ekle
          </Typography>
        </Button>
      )}

      {!isMobile && (
        <Button
          sx={{
            display: "flex",
            gap: "12px",
          }}
          onClick={logOut}
        >
          <LogoutIcon />
          <Typography variant="subtitle2" noWrap sx={{ color: "text.primary" }}>
            Çıkış Yap
          </Typography>
        </Button>
      )}
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
            },
          }),
        }}
      >
        <Avatar
          src={account.photoURL}
          alt="photoURL"
          style={{ backgroundColor: "primary.main", marginLeft: "20px" }}
        />
      </IconButton>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="subtitle2" noWrap sx={{ color: "text.primary" }}>
          {account.displayName}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.disabled" }} noWrap>
          {sessionStorage.getItem("Mail")}
        </Typography>
      </Box>
      {isMobile && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 29,

              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2, width: "15rem" }}>
            <Typography variant="subtitle2" noWrap>
              {account.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {sessionStorage.getItem("Mail")}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: "dashed" }} />

          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => {
                  handleClose();

                  if (option.label === "Anasayfa") {
                    navigate("/dashboard");
                  } else if (option.label === "Vendor Ekle") {
                    navigate("/dashboard/vendorIslemleri");
                  } else if (option.label == "Tema Değiştir") {
                    dispatch(setDarkMode(!darkMode));
                    if (!darkMode) {
                      localStorage.setItem("darkMode", "true");
                      console.log("true");
                    } else {
                      localStorage.setItem("darkMode", "false");
                      console.log("false");
                    }
                  }
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Stack>

          <Divider sx={{ borderStyle: "dashed" }} />

          <MenuItem onClick={() => [handleClose(), logOut()]} sx={{ m: 1 }}>
            Çıkış Yap
          </MenuItem>
        </Popover>
      )}
    </>
  );
}
