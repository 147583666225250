import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GetAllVendorsWithDetails } from "src/services/api";
const VendorSelect = ({
  variant,
  vendor,
  setVendor,
  errorVendor,
  setErrorVendor,
  setVendorInfo,
}) => {
  const [vendors, setVendors] = useState([]);
  const handleChangeVendor = (event) => {
    setVendor(event.target.value);
    setErrorVendor(false);
    const selectedVendorSuffix = event.target.value;
    const selectedVendor = vendors.find(
      (v) => v.VendorSuffix === selectedVendorSuffix
    );
    if (selectedVendor) {
      setVendorInfo(selectedVendor);
    }
  };

  useEffect(() => {
    GetAllVendorsWithDetails(setVendors);
  }, [vendor]);

  return (
    <FormControl
      sx={{ minWidth: 135 }}
      variant={variant ? variant : "standard"}
      error={errorVendor}
    >
      <InputLabel id="demo-simple-select-label"> Vendor Seçiniz</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={vendor}
        label="Vendor Seçiniz"
        onChange={handleChangeVendor}
      >
        {vendors.map((vendor) => (
          <MenuItem key={vendor.VendorSuffix} value={vendor.VendorSuffix}>
            {vendor.VendorName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VendorSelect;
