import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";
import LogoTry from "src/utils/images/logoTry";
import {
  startBigTitle,
  startButton,
  startGrid,
  startLogo,
  startPageContainer,
  startSmallText,
  startText,
} from "src/utils/customStyles";

function StartingPage(props) {
  const navigate = useNavigate();

  return (
    <Box style={startPageContainer}>
      <Box sx={startLogo}>
         
        <LogoTry />
      </Box>

      <Box sx={startText}>
         <Typography sx={startSmallText}>Goldtag Admin Panel</Typography>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={startGrid}
      >
        <Grid
          item
          xs={6}
          sx={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <IconButton
            sx={startButton}
            onClick={() => {
              navigate("/LoginVendor");
            }}
          >
            <Typography sx={startBigTitle}>Goldtag Gateway</Typography>
          </IconButton>{" "}
        </Grid>
        <Grid item xs={6}>
          <IconButton
            sx={startButton}
            onClick={() => {
              navigate("/LoginCrypto");
            }}
          >
            <Typography sx={startBigTitle}>Goldtag Currencies</Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StartingPage;
