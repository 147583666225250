import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import global from "src/Global";
import DigitBox from "./DigitBox";
import { ValidateLoginAuthenticator } from "src/services/api";
import { rzlt, rzltwhite } from "src/theme/customStyles";
import { useSelector } from "react-redux";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";

// components

// -----------------------------------------------------------------------

export default function AuthForm(props) {
  const [validationCode, setValidationCode] = useState(new Array(6).fill(""));
  const [combinedCode, setCombinedCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(true);
  const [loginMessage, setLoginMessage] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.value);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);

  global.MAIL = props.mail;
  sessionStorage.setItem("Mail", global.MAIL);

  const navigate = useNavigate();

  useEffect(() => {
    const combined = validationCode.join("");
    setCombinedCode(combined);
  }, [validationCode]);

  async function LogInto() {
    setLoading(true);

    try {
      const response = await ValidateLoginAuthenticator(props.id, combinedCode);
      console.log("that the response:", response);

      if (response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        navigate("/dashboard/Menu");
      } else {
        setAlertMessage(response.Message);
        setAlertStatus(false);
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    if (combinedCode.length > 5) {
      LogInto();
    }
  }, [combinedCode]);

  return (
    <>
      <Typography>{sessionStorage.getItem("fetchUrl")}</Typography>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "top" }}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      <Box sx={[darkMode ? rzltwhite : rzlt, { height: "500px" }]}>
        <Typography
          sx={{ fontSize: 24, marginBottom: "0.5rem", textAlign: "center" }}
        >
          6 Haneli doğrulama kodunu girin.
        </Typography>
        <Typography sx={{ textDecoration: "underline" }}>
          {props.mail}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <DigitBox
            validationCode={validationCode}
            setValidationCode={setValidationCode}
          />
        </Box>
        {loginStatus ? (
          ""
        ) : (
          <Typography
            sx={{
              color: "red",
              alignSelf: "center",
              marginTop: "1rem",
              fontStyle: "italic",
            }}
          >
            {loginMessage}
          </Typography>
        )}
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ width: "20rem" }}
          onClick={() => {
            LogInto();
          }}
          disabled={combinedCode.length <= 5}
        >
          Giriş Yap
        </Button>

        <LoadingOverlay isOpen={loading} />
      </Box>
    </>
  );
}
