import * as React from "react";
const TransfersIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21.742} {...props}>
    <path
      d="M18.8 1.293a3.151 3.151 0 0 0-2.971 2.1h-9.1a4.725 4.725 0 0 0 0 9.45h11.55a2.625 2.625 0 0 1 0 5.25h-3.24l1.358-1.358-1.49-1.485-3.892 3.892 3.892 3.892 1.485-1.485-1.358-1.358h3.24a4.725 4.725 0 0 0 0-9.45H6.725a2.625 2.625 0 0 1 0-5.25h9.1a3.15 3.15 0 1 0 2.971-4.2zm1.05 3.15a1.05 1.05 0 1 1-1.05-1.05 1.05 1.05 0 0 1 1.05 1.05zM6.2 22.293a3.15 3.15 0 1 1 3.15-3.15 3.15 3.15 0 0 1-3.15 3.15zm1.05-3.15a1.05 1.05 0 1 1-1.05-1.05 1.05 1.05 0 0 1 1.05 1.05z"
      data-name="24 / maps / route"
      style={{
        fill: props.darkMode ? "#383838" : "#fff",
        fillRule: "evenodd",
      }}
      transform="translate(-2 -1.293)"
    />
  </svg>
);
export default TransfersIcon;
