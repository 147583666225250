import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VendorSelect from "src/components/Select/VendorSelect";

import { UpdateVendor, createVendor } from "src/services/api";
import { pageContainer } from "src/utils/customStyles";

const VendorOperations = () => {
  const location = useLocation();
  const initialTabValue =
    (location.state?.value ?? Number(localStorage.getItem("tabValueVendor"))) ||
    0;

  const [updateForm, setUpdateForm] = React.useState({
    BannedCommoditySuffixes: [],
    IsActive: false,
    VendorEmail: "",
    VendorLegalName: "",
    VendorName: "",
    VendorPhone: "",
    VendorSuffix: "",
  });
  useEffect(() => {}, []);

  const [vendor, setVendor] = useState({
    VendorName: "",
    VendorEmail: "",
    VendorPhone: "",
    VendorLegalName: "",
  });
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [errorVendor, setErrorVendor] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});
  const [errorEmtia, setErrorEmtia] = useState(false);

  const [vendorKey, setVendorKey] = useState(0);
  const [value, setValue] = useState(initialTabValue);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("tabValueVendor", newValue);
  };
  const handleChangeVendor = (prop) => (event) => {
    setVendor({ ...vendor, [prop]: event.target.value });
    console.log(vendor);
    console.log("----");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Burada API çağrısını entegre edin
    console.log("Submitting", vendor);
  };

  const handleChangeActivePass = (event) => {
    setUpdateForm((prevState) => ({
      ...prevState,
      IsActive: event.target.value,
    }));
    setErrorEmtia(false);
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    console.log(`ID: ${id}, Value: ${value}`);
    setUpdateForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  useEffect(() => {
    setUpdateForm(vendorInfo);
  }, [vendorInfo]);
  useEffect(() => {
    return () => {
      localStorage.removeItem("tabValueVendor");
    };
  }, []);
  const onClickUpdateVendor = async () => {
    try {
      setOpen(true);
      const response = await UpdateVendor(updateForm);
      console.log(response);
      response.Success == false ? setAlertStatus(false) : setAlertStatus(true);
      setAlertMessage(response.Message);
      setOpenAlert(true);
    } catch (error) {
      setAlertMessage(error.Message);
      setAlertStatus(false);
      setOpenAlert(true);
      console.error("Hata oluştu:", error);
    } finally {
      setTimeout(() => {
        setOpenAlert(false);
        setOpen(false);
      }, 1000);
    }
  };
  const onClickCreateVendor = async () => {
    if (
      vendor.VendorName !== "" &&
      vendor.VendorLegalName !== "" &&
      vendor.VendorEmail !== "" &&
      vendor.VendorPhone !== ""
    ) {
      try {
        setOpen(true);
        const response = await createVendor(vendor);
        console.log(response);
        response.Success == false
          ? setAlertStatus(false)
          : setAlertStatus(true);
        setAlertMessage(response.Message);
        setOpenAlert(true);
      } catch (error) {
        setAlertMessage(error.Message);
        setAlertStatus(false);
        setOpenAlert(true);
        console.error("Hata oluştu:", error);
      } finally {
        setTimeout(() => {
          setOpenAlert(false);
          setOpen(false);
        }, 1000);
      }
    } else {
      setAlertMessage("Boş alan bırakılamaz!");
      setAlertStatus(false);
      setOpenAlert(true);
    }
  };
  function VendorUpdateForm() {
    return (
      <Card
        sx={{
          p: 3,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: { md: "30rem", xs: "20rem" },
          margin: "0 auto",
          marginTop: 5,
          minWidth: "20rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Vendor Güncelle
        </Typography>
        <Divider
          sx={{
            width: "10%",
            backgroundColor: "primary.goldtag",
            height: 3,
            mb: 3,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <VendorSelect
            key={vendorKey}
            vendor={updateForm.VendorSuffix}
            setVendor={(vendor) =>
              setUpdateForm((prevState) => ({
                ...prevState,
                VendorSuffix: vendor,
              }))
            }
            errorVendor={errorVendor}
            setErrorVendor={setErrorVendor}
            variant="outlined"
            setVendorInfo={setVendorInfo}
          />
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Vendor adı giriniz"
              id="VendorName"
              variant="outlined"
              value={updateForm.VendorName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="E-mail giriniz"
              id="VendorEmail"
              variant="outlined"
              value={updateForm.VendorEmail}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              type="number"
              label="Telefon numarası giriniz"
              id="VendorPhone"
              variant="outlined"
              value={updateForm.VendorPhone}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Resmi adını giriniz"
              id="VendorLegalName"
              variant="outlined"
              value={updateForm.VendorLegalName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Durumunu seçiniz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={updateForm.IsActive}
              label="Durumunu seçiniz"
              onChange={handleChangeActivePass}
            >
              <MenuItem key={"active"} value={true}>
                Aktif
              </MenuItem>
              <MenuItem key={"passive"} value={false}>
                Pasif
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={() => [
              onClickUpdateVendor(),
              setVendorKey((prevKey) => prevKey + 1),
            ]}
          >
            Güncelle
          </Button>
        </Box>
      </Card>
    );
  }

  return (
    <Box sx={pageContainer}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography
        variant="h3"
        sx={{
          letterSpacing: "3px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          alignItems: "center",
          alignSelf: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
          paddingLeft: "3rem",
        }}
      >
        Vendor İşlemleri
        <Divider
          sx={{
            width: "100px",
            backgroundColor: "primary.goldtag",
            height: 2,
          }}
        />
      </Typography>
      <Box
        sx={{
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <Button
          key={"btn0" + value}
          sx={{
            backgroundColor: value === 0 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(0)}
          variant="contained"
          fullWidth
        >
          vendor oluştur
        </Button>
        <Button
          key={"btn1" + value}
          sx={{
            backgroundColor: value === 1 ? "#7A4F01" : "default",
          }}
          onClick={() => handleChange(1)}
          variant="contained"
          fullWidth
        >
          vendor güncelle
        </Button>
      </Box>

      {value == 0 && (
        <Card
          sx={{
            width: { md: "30rem", xs: "20rem" },
            mx: "auto",
            mt: 5,
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Vendor Ekle
            </Typography>
            <Divider
              sx={{
                width: "10%",
                backgroundColor: "primary.goldtag",
                height: 3,
              }}
            />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Adı"
                    value={vendor.VendorName}
                    onChange={handleChangeVendor("VendorName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Resmi İsmi"
                    value={vendor.VendorLegalName}
                    onChange={handleChangeVendor("VendorLegalName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Email"
                    value={vendor.VendorEmail}
                    onChange={handleChangeVendor("VendorEmail")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Vendor Telefon Numarası"
                    value={vendor.VendorPhone}
                    onChange={handleChangeVendor("VendorPhone")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => onClickCreateVendor()}
                    // onClick={() => {
                    //   if (
                    //     vendor.VendorName !== "" &&
                    //     vendor.VendorLegalName !== "" &&
                    //     vendor.VendorEmail !== "" &&
                    //     vendor.VendorPhone !== ""
                    //   ) {
                    //     createVendor({
                    //       vendor: vendor,
                    //       setOpen: setOpen,
                    //       setAlertMessage: setAlertMessage,
                    //       setAlertStatus: setAlertStatus,
                    //     });
                    //     setOpen(true);
                    //     setOpenAlert(true);
                    //   } else {
                    //     setAlertMessage("Boş alan bırakılamaz");
                    //     setAlertStatus(false);
                    //     setOpenAlert(true);
                    //   }
                    // }}
                  >
                    Vendor Ekle
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}

      {value == 1 && VendorUpdateForm()}

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VendorOperations;
