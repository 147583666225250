import { Box, Grid, Typography } from "@mui/material";
import gsap from "gsap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Users from "src/assets/Images/User";
import { cardb, cardss, cardt, svgBgstyle } from "src/theme/customStyles";
import { formatCurrency, getBackgroundColor } from "src/utils/helpers/helper";
import RemoveIcon from "@mui/icons-material/Remove";
function TransactionCards({ emtia, data }) {
  const darkMode = useSelector((state) => state.darkMode.value);
  const dataArray = Object.entries(data).map(([key, value]) => ({
    key,
    value,
  }));

  console.log(dataArray);

  useEffect(() => {
    gsap.from(".custom-card", {
      duration: 1, // Animasyon süresi
      opacity: 0, // Başlangıçta opaklık 0
      x: -50, // Başlangıçta -100px soldan gelerek başlayacak
      ease: "power3.out", // Animasyonun hareket eğrisi
      stagger: 0.2, // Her kart arasında 0.2 saniye gecikme
    });
  }, [data]);

  const cardStyle = {
    height: "100px",
    flexDirection: "row",
    alignItems: "center",
    width: "314px",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      transition: "padding 0.5s ease",
      padding: "12px 24px",
    },
  };

  const emtiaImage = () => {
    if (emtia === 1) {
      return "gold";
    } else if (emtia === 2) {
      return "silver";
    } else if (emtia === 3) {
      return "platinium";
    } else {
      // Varsayılan durum için gold kullan
      return "gold";
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {dataArray.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  ...cardStyle,
                },
              ]}
            >
              <Box sx={svgBgstyle}>
                <img
                  src={require(`../../../assets/Images/${emtiaImage()}.png`)}
                  alt={"gold"}
                  width={32}
                  height={32}
                />
              </Box>
              <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
                <Typography sx={[cardb]}>Toplam Alınan TL Karşılığı</Typography>
                <Typography sx={[cardb]}>
                  {formatCurrency(dataArray[1]?.value)} ₺
                </Typography>
              </Box>
            </Box>
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  ...cardStyle,
                },
              ]}
            >
              <Box sx={svgBgstyle}>
                <img
                  src={require(`../../../assets/Images/${emtiaImage()}.png`)}
                  alt={"gold"}
                  width={32}
                  height={32}
                />
              </Box>
              <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
                <Typography sx={[cardb]}>
                  Toplam Satılan TL Karşılığı
                </Typography>
                <Typography sx={[cardb]}>
                  {formatCurrency(dataArray[3]?.value)} ₺
                </Typography>{" "}
              </Box>
            </Box>
            <RemoveIcon />
            <Box
              sx={{
                display: "flex",
                width: "97%",
                height: "0.1rem",
                backgroundColor: "divider",
                marginBottom: "0.5rem",
                background: getBackgroundColor(`${emtia}`),
              }}
            />
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  height: "50px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "314px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  "&:hover": {
                    transition: "padding 0.5s ease",
                    padding: "12px 24px",
                  },
                },
              ]}
            >
              <Typography sx={[cardt]}>
                {formatCurrency(
                  (dataArray[1]?.value - dataArray[3]?.value).toFixed(2)
                )}{" "}
                ₺
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  ...cardStyle,
                },
              ]}
            >
              <Box sx={svgBgstyle}>
                <img
                  src={require(`../../../assets/Images/${emtiaImage()}.png`)}
                  alt={"gold"}
                  width={32}
                  height={32}
                />
              </Box>
              <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
                <Typography sx={[cardb]}>Toplam Alınan Gr Miktarı</Typography>{" "}
                <Typography sx={[cardb]}>{dataArray[0]?.value} gr</Typography>
              </Box>
            </Box>
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  ...cardStyle,
                },
              ]}
            >
              <Box sx={svgBgstyle}>
                <img
                  src={require(`../../../assets/Images/${emtiaImage()}.png`)}
                  alt={"gold"}
                  width={32}
                  height={32}
                />
              </Box>
              <Box sx={{ marginBottom: "auto", marginTop: "auto" }}>
                <Typography sx={[cardb]}>Toplam Satılan Gr Miktarı</Typography>{" "}
                <Typography sx={[cardb]}>{dataArray[2]?.value} gr</Typography>
              </Box>
            </Box>
            <RemoveIcon />
            <Box
              sx={{
                display: "flex",
                width: "97%",
                height: "0.1rem",
                backgroundColor: "divider",
                marginBottom: "0.5rem",
                background: getBackgroundColor(`${emtia}`),
              }}
            />
            <Box
              className="custom-card"
              sx={[
                cardss,
                {
                  background: getBackgroundColor(`${emtia}`),
                  height: "50px",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "314px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  "&:hover": {
                    transition: "padding 0.5s ease",
                    padding: "12px 24px",
                  },
                },
              ]}
            >
              <Typography sx={[cardt]}>
                {(dataArray[0]?.value - dataArray[2]?.value).toFixed(2)} gr
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default TransactionCards;
