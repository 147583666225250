import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  IconButton,
} from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import NavSection from "../../../components/nav-section";
//
import navConfig from "./config";
import { lightTitle12, lightTitle14 } from "src/utils/customStyles";
import { setNav } from "src/Redux/slices/navSlice";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [url, setUrl] = useState(sessionStorage.getItem("FetchUrl"));

  const isDesktop = useResponsive("up", "lg");
  const nav = useSelector((state) => state.nav.value);
  const darkMode = useSelector((state) => state.darkMode.value);

  const dispatch = useDispatch();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <Box sx={{ mx: 2.5 }}>
        <Link underline="none">
          <Typography
            sx={[
              lightTitle12,
              {
                marginBottom: "1rem",
                opacity: 0.3,
                color: "common.black",
              },
            ]}
          >
            {sessionStorage.getItem("fetchUrl")}
          </Typography>
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "navText",
                  textAlign: "center",
                  paddingRight: "1rem",
                }}
              >
                {account.displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: "navText" }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          mb: 0.5,
          mt: 2.5,
        }}
      />

      <NavSection data={navConfig} darkMode={darkMode} />

      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: nav && { lg: NAV_WIDTH },
          position: "fixed",
          height: "100vh",
          zIndex: 1111,
        }}
      >
        {isDesktop ? (
          <Box
            sx={{
              width: nav ? NAV_WIDTH : 0,
              transition: "width 0.3s",
              overflow: "hidden",
              backgroundColor: "nav",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "12px",
            }}
          >
            {renderContent}
          </Box>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH, backgroundColor: "nav" },
            }}
          >
            <Box style={{ height: "120px" }} />
            {renderContent}
            <Typography
              sx={[
                lightTitle14,
                {
                  color: "#383838",
                  // opacity: 0.1,
                  alignSelf: "flex-end",
                  textAlign: "center",
                  display: "flex",
                },
              ]}
            >
              {url}
            </Typography>
          </Drawer>
        )}
      </Box>
    </>
  );
}
