import { axiosInstance, loginAxiosInstance } from "./axios/axiosConfig";

export async function AdminLogin(mailValue, passValue) {
  const raw = {
    UserName: mailValue,
    Password: passValue,
  };

  return loginAxiosInstance
    .post("/AdminLogin", raw)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function ValidateLoginAuthenticator(UserId, Token) {
  const raw = {
    UserId: UserId,
    Token: Token,
  };

  try {
    const response = await loginAxiosInstance.post(
      "/ValidateLoginAuthenticator",
      raw
    );
    console.log(response);

    if (response.data && response.data.Success) {
      const authCode = response.data.Data;
      sessionStorage.setItem("authCode", authCode);
    }

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return error.response.data;
  }
}

export async function Enable2FA(UserId) {
  const raw = {
    UserId: UserId,
    Enable: true,
  };

  try {
    const response = await loginAxiosInstance.post("/Enable2FA", raw);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return error.response.data;
  }
}

export async function Verify2FAFunc(UserId, Token) {
  const raw = {
    UserId: UserId,
    Token: Token,
  };

  try {
    const response = await loginAxiosInstance.post("/Verify2FA", raw);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    return error.response.data;
  }
}

export async function createVendor(vendor) {
  console.log(vendor);

  try {
    console.log(vendor);
    const response = await axiosInstance.post("/CreateVendor", vendor);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function setPrice(body) {
  try {
    const response = await axiosInstance.post("/SetPriceProfit", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function setFintagSourcePrice(body) {
  console.log(body);
  try {
    const response = await axiosInstance.post("/SetFintagSourcePrice", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function setActiveProviderPrice(body) {
  try {
    const response = await axiosInstance.post("/SetActiveProviderPrice", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function GetAllTransactions(body) {
  try {
    const response = await axiosInstance.post("/GetAllTransactions", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function GetAllVendorsWithDetails(setData) {
  axiosInstance
    .get("/GetAllVendorsWithDetails")
    .then((response) => {
      setData(response.data.Data); // Doğru alana erişim
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}

export async function GetGatewayPrices(setData) {
  axiosInstance
    .get("/GetGatewayPrices")
    .then((response) => {
      setData(response.data.Data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}

export async function UpdateVendor(updateData) {
  console.log(updateData);
  try {
    console.log(updateData);
    const response = await axiosInstance.post("/UpdateVendor", updateData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function StopAllCommodities(updateData) {
  axiosInstance
    .post("/StopAllCommodities", updateData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function StartAllCommodities() {
  axiosInstance
    .post("/StartAllCommodities")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
export async function GetAdmins() {
  try {
    const response = await axiosInstance.get("/GetAdmins");
    console.log(response.data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
  // axiosInstance
  //   .get("/GetAdmins")
  //   .then((response) => {
  //     //
  //     setAlertMessage && setAlertMessage(response.data.Message);
  //     setAlertStatus &&
  //       setAlertStatus(
  //         response.data.Message == "Başarılı sonuç." ? true : false
  //       );
  //     setData && setData(response.data.Data.userAdminRoles);
  //     setRoleNames && setRoleNames(response.data.Data.adminRoles);
  //     setAdmins && setAdmins(response.data.Data.adminUsers);
  //     if (setOpen) {
  //       setTimeout(() => {
  //         setOpen(false);
  //       }, 2000);
  //     }
  //   })
  //   .catch((error) => {
  //     if (setOpen) {
  //       setTimeout(() => {
  //         setOpen(false);
  //       }, 2000);
  //     }
  //     setData && setData([]);
  //     setAlertMessage && setAlertMessage("Hata!");
  //     setAlertStatus && setAlertStatus(false);
  //     console.error("Error fetching data:", error);
  // });
}
export async function AddRoleToUser(updateData) {
  console.log(updateData);
  try {
    console.log(updateData);
    const response = await axiosInstance.post("/AddRoleToUser", updateData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function CreateRole(updateData) {
  console.log(updateData);
  try {
    console.log(updateData);
    const response = await axiosInstance.post("/CreateRole", updateData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function UpdateRole(updateData) {
  try {
    console.log(updateData);
    const response = await axiosInstance.post("/UpdateRole", updateData);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function CreateAdminUser(data) {
  try {
    console.log(data);
    const response = await axiosInstance.post("/CreateAdminUser", data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
