import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { cloneElement } from "react";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  darkMode: PropTypes.bool, // darkMode prop'unu ekledik
};

export default function NavSection({ data = [], darkMode, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} darkMode={darkMode} /> // darkMode prop'unu geçiyoruz
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  darkMode: PropTypes.bool, // darkMode prop'unu ekledik
};

function NavItem({ item, darkMode }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: "text.navsec",
        "&.active": {
          color: "navText",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>
        {icon && cloneElement(icon, { darkMode })}
      </StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
