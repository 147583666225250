import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField } from "@mui/material";

const DateRangePicker = ({
  setStartDay,
  setStartMonth,
  setStartYear,
  setEndDay,
  setEndMonth,
  setEndYear,
  errorStart,
  errorEnd,
  setErrorStart,
  setErrorEnd,
  type,
}) => {
  const [startDate, setStartDate] = useState("00/00/0000");
  const [endDate, setEndDate] = useState("00/00/0000");
  const handleChangeStart = (newValue) => {
    setStartDate(newValue);
    setErrorStart(false);
    if (newValue) {
      setStartDay(
        newValue.$D < 10 ? "0" + newValue.$D : newValue.$D.toString()
      );
      setStartMonth(
        newValue.$M < 9 ? "0" + (newValue.$M + 1) : (newValue.$M + 1).toString()
      );
      setStartYear(newValue.$y.toString());
    } else {
      setStartDay("");
      setStartMonth("");
      setStartYear("");
    }
  };

  const handleChangeEnd = (newValue) => {
    setErrorEnd(false);
    setEndDate(newValue);
    if (newValue) {
      setEndDay(newValue.$D < 10 ? "0" + newValue.$D : newValue.$D.toString());
      setEndMonth(
        newValue.$M < 9 ? "0" + (newValue.$M + 1) : (newValue.$M + 1).toString()
      );
      setEndYear(newValue.$y.toString());
    } else {
      setEndDay("");
      setEndMonth("");
      setEndYear("");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Başlangıç Tarihi"
          value={startDate}
          onChange={handleChangeStart}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "170px" }}
              size="small"
              variant="standard"
              error={errorStart}
            />
          )}
        />
        {type == "range" && (
          <DatePicker
            label="Bitiş Tarihi"
            value={endDate}
            onChange={handleChangeEnd}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ marginLeft: 2, width: "170px" }}
                size="small"
                variant="standard"
                error={errorEnd}
              />
            )}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangePicker;
